import LogoAlphalitical from 'src/assets/image/logo_alphalitical.png';
import LogoNoLimit from 'src/assets/image/nolimit-logo-flat.svg';
import LogoNoLimitWhite from 'src/assets/image/logoNoLimitWhite.svg';
import LogoPdip from 'src/assets/image/logo_pdip.png';
import LogoKabCiamis from 'src/assets/image/logo_kabciamis.png';
import LogoKemhan from 'src/assets/image/logo_kemenhan.png';
import LogoBais from 'src/assets/image/logo_bais.png';
import LogoKadence from 'src/assets/image/logo_kadence.png';
import LogoKadenceWhite from 'src/assets/image/logo_kadence_white.png';
import logoKompas from 'src/assets/image/logo_kompas.png';
import logoSkema from 'src/assets/image/logo_skema.png';
import logoGcomm from 'src/assets/image/logo_gcomm.png';
import logoGcommWhite from 'src/assets/image/logo_gcomm_white.png';
import logoPln from 'src/assets/image/logo_pln.png';
import logoPlnWhite from 'src/assets/image/logo_pln_white.png';
import tniAuLogo from 'src/assets/image/tni-au-logo.png';
import tniAuLogoWhite from 'src/assets/image/tni-au-logo-white.png';
import telAccessLogo from 'src/assets/image/logo_tel_access.png';
import telAccessLogoWhite from 'src/assets/image/logo_tel_access_white.png';
import logoDiy from 'src/assets/image/logo_diy.png';
import logoBbdo from 'src/assets/image/logo_bbdo.png';
import logoValdo from 'src/assets/image/valdo-logo.png';
import logoValdoWhite from 'src/assets/image/valdo-logo-white.png';
import logoKomdigi from 'src/assets/image/logo_komdigi.png';
import logoKomdigiWhite from 'src/assets/image/logo_komdigi_white.png';

export const getLogoByHostname = function (isLogin?: boolean) {
  if (window.location.hostname.includes('alphalitical')) {
    return { logo: LogoAlphalitical, width: isLogin ? '120px' : '80px' };
  }
  if (window.location.hostname.includes('pdip')) {
    return { logo: LogoPdip, width: isLogin ? '70px' : '50px' };
  }
  if (window.location.hostname.includes('pemkabciamis')) {
    return { logo: LogoKabCiamis, width: isLogin ? '72px' : '55px' };
  }
  if (window.location.hostname.includes('kemhan')) {
    return { logo: LogoKemhan, width: isLogin ? '70px' : '50px' };
  }
  if (window.location.hostname.includes('legasysolution'))
    return { logo: LogoBais, width: isLogin ? '70px' : '60px' };

  if (window.location.hostname.includes('kadence') && isLogin)
    return { logo: LogoKadence, width: '141px' };

  if (window.location.hostname.includes('kadence'))
    return { logo: LogoKadenceWhite, width: '140px' };

  if (window.location.hostname.includes('kompas'))
    return { logo: logoKompas, width: '150px' };

  if (window.location.hostname.includes('skema'))
    return { logo: logoSkema, width: '100px' };

  if (window.location.hostname.includes('gcomm')) {
    if (isLogin) return { logo: logoGcomm, width: '100px' };
    return { logo: logoGcommWhite, width: '100px' };
  }

  if (window.location.hostname.includes('ps-techno')) {
    if (isLogin) return { logo: logoPln, width: '100px' };
    return { logo: logoPlnWhite, width: '100px' };
  }

  if (window.location.hostname.includes('tni-au')) {
    if (isLogin) return { logo: tniAuLogo, width: '150px' };
    return { logo: tniAuLogoWhite, width: '150px' };
  }
  if (window.location.hostname.includes('tel-access')) {
    return {
      logo: isLogin ? telAccessLogo : telAccessLogoWhite,
      width: isLogin ? '120px' : '90px'
    };
  }
  if (window.location.hostname.includes('elingjogja')) {
    return { logo: logoDiy, width: isLogin ? '120px' : '90px' };
  }
  if (window.location.hostname.includes('bbdo')) {
    return { logo: logoBbdo, width: '150px' };
  }

  if (window.location.hostname.includes('valdo')) {
    if (isLogin) return { logo: logoValdo, width: '150px' };
    return { logo: logoValdoWhite, width: '150px' };
  }

  if (window.location.hostname.includes('komdigi')) {
    if (isLogin) return { logo: logoKomdigi, width: '60px' };
    return { logo: logoKomdigi, width: '60px' };
  }

  if (isLogin) return { logo: logoKomdigi, width: '80px' };
  return { logo: logoKomdigiWhite, width: '70px' };
};
